import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
 
const TOKEN_KEY = 'auth-token';
let apiUrl = 'http://ipsanet.com/bknd';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 
  authenticationState = new BehaviorSubject(false);
 
  constructor(private storage: Storage, private plt: Platform,public http: HttpClient) { 
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }
 
  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    })
  }
 
  /*login() {
    return this.storage.set(TOKEN_KEY, 'Bearer 1234567').then(() => {
      this.authenticationState.next(true);
    });
  }
  */
 login(data): Observable<any> {
  const url = apiUrl+'/login.php';
  return this.http.post<any>(url,data).pipe(
    tap(() => console.log('la consulta paso')),
    catchError(this.handleError<any>('se presento un error'))
  );
}
	
	
	
 
  logout() {
    return this.storage.clear().then(() => {
      this.authenticationState.next(false);
    });
  }
 
  isAuthenticated() {
    return this.authenticationState.value;
  }
	
	
  registro(data): Observable<any> {
	  const url = apiUrl+'/registro.php';
	  return this.http.post<any>(url,data).pipe(
		tap(() => console.log('la consulta paso')),
		catchError(this.handleError<any>('se presento un error'))
	  );
  }
	

	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {

		// TODO: send the error to remote logging infrastructure
		console.error(error); // log to console instead

		// Let the app keep running by returning an empty result.
		return of(result as T);
	  };
	}
 
}